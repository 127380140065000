import validate from "/tmp/8dd0012f3f1f99c/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/tmp/8dd0012f3f1f99c/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/tmp/8dd0012f3f1f99c/middleware/auth.ts"),
  onboarding: () => import("/tmp/8dd0012f3f1f99c/middleware/onboarding.ts"),
  "recruiter-only": () => import("/tmp/8dd0012f3f1f99c/middleware/recruiterOnly.ts")
}