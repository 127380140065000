import { default as _91id_93iNhggrHX14Meta } from "/tmp/8dd0012f3f1f99c/pages/applicant/[id].vue?macro=true";
import { default as _91id_93cmTP1MoMihMeta } from "/tmp/8dd0012f3f1f99c/pages/apply/[id].vue?macro=true";
import { default as _91id_93cRfINQIVNNMeta } from "/tmp/8dd0012f3f1f99c/pages/apply/perfil/[id].vue?macro=true";
import { default as thanksl1yP2h4cUjMeta } from "/tmp/8dd0012f3f1f99c/pages/apply/thanks.vue?macro=true";
import { default as _91_46_46_46slug_93eBCdaxg3XrMeta } from "/tmp/8dd0012f3f1f99c/pages/auth/password/[...slug].vue?macro=true";
import { default as forgotcmdm8HpqayMeta } from "/tmp/8dd0012f3f1f99c/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_93vztbAv2juTMeta } from "/tmp/8dd0012f3f1f99c/pages/auth/signin/[...slug].vue?macro=true";
import { default as index80fmVub2CrMeta } from "/tmp/8dd0012f3f1f99c/pages/auth/signin/index.vue?macro=true";
import { default as _91email_93BemISIx0qMMeta } from "/tmp/8dd0012f3f1f99c/pages/auth/signup/[email].vue?macro=true";
import { default as indexiJX1hfIf7XMeta } from "/tmp/8dd0012f3f1f99c/pages/auth/signup/index.vue?macro=true";
import { default as _91_46_46_46slug_933smy4mSmvwMeta } from "/tmp/8dd0012f3f1f99c/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as jobsPjuN6ggrGHMeta } from "/tmp/8dd0012f3f1f99c/pages/company/[id]/jobs.vue?macro=true";
import { default as indexh0mwS3Ysc5Meta } from "/tmp/8dd0012f3f1f99c/pages/company/index.vue?macro=true";
import { default as onboardingqn22s2zZLJMeta } from "/tmp/8dd0012f3f1f99c/pages/company/onboarding.vue?macro=true";
import { default as index9bi040IToYMeta } from "/tmp/8dd0012f3f1f99c/pages/index.vue?macro=true";
import { default as _91applicant_93ltRSDBCx46Meta } from "/tmp/8dd0012f3f1f99c/pages/job/[job]/applicants/[applicant].vue?macro=true";
import { default as indexdHZw3baeesMeta } from "/tmp/8dd0012f3f1f99c/pages/job/[job]/applicants/index.vue?macro=true";
import { default as duplicateG6DsQTLiVxMeta } from "/tmp/8dd0012f3f1f99c/pages/job/[job]/duplicate.vue?macro=true";
import { default as indexFl8yjhbLb8Meta } from "/tmp/8dd0012f3f1f99c/pages/job/[job]/index.vue?macro=true";
import { default as archivedJXhTd2CiEJMeta } from "/tmp/8dd0012f3f1f99c/pages/job/archived.vue?macro=true";
import { default as closed0TZr1B0NPhMeta } from "/tmp/8dd0012f3f1f99c/pages/job/closed.vue?macro=true";
import { default as newn096dS0cdHMeta } from "/tmp/8dd0012f3f1f99c/pages/job/new.vue?macro=true";
import { default as openedg6Cx9io8AAMeta } from "/tmp/8dd0012f3f1f99c/pages/job/opened.vue?macro=true";
import { default as pausedGMV55coThIMeta } from "/tmp/8dd0012f3f1f99c/pages/job/paused.vue?macro=true";
import { default as indexGeVoOwca7PMeta } from "/tmp/8dd0012f3f1f99c/pages/plans/index.vue?macro=true";
import { default as settingsKgJrT9RU1vMeta } from "/tmp/8dd0012f3f1f99c/pages/settings.vue?macro=true";
import { default as indexA2cXzsqVFUMeta } from "/tmp/8dd0012f3f1f99c/pages/thanks/index.vue?macro=true";
import { default as indexz6K5PHO9JMMeta } from "/tmp/8dd0012f3f1f99c/pages/user/index.vue?macro=true";
export default [
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_93iNhggrHX14Meta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/applicant/[id].vue")
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dd0012f3f1f99c/pages/apply/[id].vue")
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dd0012f3f1f99c/pages/apply/perfil/[id].vue")
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dd0012f3f1f99c/pages/apply/thanks.vue")
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dd0012f3f1f99c/pages/auth/password/[...slug].vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dd0012f3f1f99c/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_93vztbAv2juTMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/auth/signin/[...slug].vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: index80fmVub2CrMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/auth/signin/index.vue")
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dd0012f3f1f99c/pages/auth/signup/[email].vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: indexiJX1hfIf7XMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/auth/signup/index.vue")
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dd0012f3f1f99c/pages/auth/verifyemail/[...slug].vue")
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dd0012f3f1f99c/pages/company/[id]/jobs.vue")
  },
  {
    name: "company",
    path: "/company",
    meta: indexh0mwS3Ysc5Meta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/company/index.vue")
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboardingqn22s2zZLJMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/company/onboarding.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index9bi040IToYMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/index.vue")
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_93ltRSDBCx46Meta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/[job]/applicants/[applicant].vue")
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: indexdHZw3baeesMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/[job]/applicants/index.vue")
  },
  {
    name: "job-job-duplicate",
    path: "/job/:job()/duplicate",
    meta: duplicateG6DsQTLiVxMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/[job]/duplicate.vue")
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: indexFl8yjhbLb8Meta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/[job]/index.vue")
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archivedJXhTd2CiEJMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/archived.vue")
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closed0TZr1B0NPhMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/closed.vue")
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: newn096dS0cdHMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/new.vue")
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: openedg6Cx9io8AAMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/opened.vue")
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: pausedGMV55coThIMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/job/paused.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexGeVoOwca7PMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/plans/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsKgJrT9RU1vMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/settings.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: indexA2cXzsqVFUMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/thanks/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexz6K5PHO9JMMeta || {},
    component: () => import("/tmp/8dd0012f3f1f99c/pages/user/index.vue")
  }
]